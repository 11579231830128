<template>
    <h5>Cotação de Seguro</h5>
    <Form @submit="updateLead" class="p-5">
        <div>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <label for="customerName">Seu Nome</label>
                    <Field name="customerName" type="text" class="form-control" v-model="customerName" />
                    <ErrorMessage name="customerName" class="error-feedback" />
                </div>
            </div>
            
            <div class="row">
                
                <div class="form-group col-md-12">
                    <label for="customerPhoneNumber">Telefone</label>
                    <Field name="customerPhoneNumber" v-maska data-maska="(##)#####-####" type="text" class="form-control" v-model="customerPhoneNumber" />
                    <ErrorMessage name="customerPhoneNumber" class="error-feedback" />
                </div>
            </div>

            
            <div>
              <div class="form-check form-check-inline">
                <Field class="form-check-input" name="cproductId" type="radio" id="one" value="1" v-model="productId" />
                <label class="form-check-label" for="one">Saúde</label>
              </div>
              <div class="form-check form-check-inline">
                <Field class="form-check-input" name="cproductId" type="radio" id="two" value="2" v-model="productId" />
                <label class="form-check-label" for="two">Auto</label>
              </div>
            </div>
            <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
                ></span>
                Enviar
            </button>
            </div>
            <div v-if="successMsg" class="alert alert-success mt-4" role="alert">
                {{successMsg}}
            </div>
        </div>
    </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import axios from 'axios'
import { vMaska } from "maska";

export default {
  name: "typeform",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  directives: { maska: vMaska },
  data() {
    

    return {
      successful: false,
      loading: false,
      message: "",
      //schema,
      contactPreference: null,
      contactTime: null,
      productId: "",
      customerName: "",
      customerPhoneNumber: "",
      successMsg: ""
    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.auth.status.loggedIn;
    // },
  },
  mounted() {
    // if (this.loggedIn) {
    //   this.$router.push("/profile");
    // }
  },
  methods: {
    async updateLead() {
      let result = this.customerPhoneNumber.replace("(", "").replace(")", "").replace("-", "");
      // console.log(this.productId);
      // console.log(this.customerName);
      // console.log(result);
      //return
        try {
            await axios.post(
                `https://desenv.ganbei.io/api/typeform-webhook`,
                {
                    productId: this.productId,
                    customerName: this.customerName,
                    customerCountryPhoneCode: "55",
                    customerPhoneNumber: "55" + result,
                    customerExtraData: {}
                }
            );
            this.successMsg = "Solicitação enviada";
            this.customerName = "";
            this.customerPhoneNumber = "";
        } catch (err) {
            console.log(err);
        }
    },
  }
}
</script>