<template>
  <div :style="{
        background: 'linear-gradient(246.89deg, #AE5EE2 0%, #00F19F 100%)',
        height: '100vh',
        backgroundSize: 'contain',
        display: 'flex',
        overflow: 'auto',
        padding: '80px 10%'
      }">
    <!--
    <div style="width:40%; padding: 0 30px;">
      <img
        style="width:30vw; position:fixed; bottom:0;max-width:385px;"
        src="../assets/img/user-login.png"
        alt="Ganbei"
      />
    </div>
    -->
    <div class="row login-card">
      <div class="col-md-6" style="padding: 40px 100px 0px;background: #fff;margin-bottom: 50px; border-radius: 10px 0 0 10px;">
        <img
          src="../assets/img/logo-ganbei.jpg"
          style="width:250px; margin:0 auto"
          class="mb-5"
        />
        <div v-if="!successful">
          <p style="font-weight: 300;">Inicie já o crescimento da sua carteira de clientes e encontre novas oportunidades de negócio.</p>
          <Form @submit="handleRegister" :validation-schema="formValidation()" class="form-register">
            <div>
              <div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                    <Field name="contactType" class="form-check-input" type="radio" id="pf" value="1" v-model="contactType" />
                    Pessoa Física
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label  class="form-check-label">
                    <Field name="contactType" class="form-check-input" type="radio" id="pj" value="2" v-model="contactType" />
                    Pessoa Jurírica
                  </label>
                </div>
              </div>
              <ErrorMessage name="contactType" class="error-feedback" />
              <div v-if="contactType == 2" class="form-group">
                <label for="companyname">Nome da corretora</label>
                <Field name="companyname" type="text" class="form-control" />
                <ErrorMessage name="companyname" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="leadname">Seu nome completo</label>
                <Field name="leadname" type="text" class="form-control" />
                <ErrorMessage name="leadname" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="email">Email</label>
                <Field name="email" type="email" class="form-control" />
                <ErrorMessage name="email" class="error-feedback" />
              </div>
              <div class="form-group">
                <label for="phone">Telefone</label>
                <Field v-maska data-maska="(##)#####-####" name="phone" type="text" class="form-control" />
                <ErrorMessage name="phone" class="error-feedback" />
              </div>
              
              <label :style="{
                      marginTop: '30px',
                      }">
                Qual a melhor forma para falarmos com você?
              </label>
        
              <div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                    <Field name="contactPreference" class="form-check-input" type="radio" id="two" value="whatsapp" v-model="contactPreference" />
                    Whatsapp
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label  class="form-check-label">
                    <Field name="contactPreference" class="form-check-input" type="radio" id="one" value="Ligação Telefônica" v-model="contactPreference" />
                    Ligação Telefonica
                  </label>
                </div>
              </div>
              <ErrorMessage name="contactPreference" class="error-feedback" />
              <!--
              <div class="mt-4">
                <label>E o Melhor horário?</label>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" for="four">  
                    <Field class="form-check-input" name="contactTime" type="radio" id="four" value="Imediatamente" v-model="contactTime" />
                    Imediatamente
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <Field class="form-check-input" name="contactTime" type="radio" id="one" value="manhã" v-model="contactTime" />
                    Manhã
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <Field class="form-check-input" name="contactTime" type="radio" id="two" value="tarde" v-model="contactTime" />
                    tarde
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label" >
                    <Field class="form-check-input" name="contactTime" type="radio" id="three" value="noite" v-model="contactTime" />
                    Noite
                  </label>
                </div>
              </div>
              <ErrorMessage name="contactTime" class="error-feedback" />
              -->
              <!--
              <div class="form-group">
                <label for="password">Senha</label>
                <Field name="password" type="password" class="form-control" />
                <ErrorMessage name="password" class="error-feedback" />
              </div>
              -->

              <div class="form-group mt-5">
                <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                  ></span>
                  Enviar
                </button>
              </div>
            </div>
          </Form>
        </div>
        <div v-if="message">
          <div
            class="alert"
            :class="successful ? 'alert-success' : 'alert-danger'"
          >
            <div v-if="successful" class="d-flex align-items-center">
              <img
              style="max-width: 60px;"
                src="@/assets/icon/sucesso.png"
              />
              <p style="margin: 0 0 0 20px;
      font-weight: 500;
      font-size: 19px;">
                Muito bem, suas informações já estão com a nossa equipe.
              </p>
            </div>
            <div v-else>
              <p>
                <span v-if="message === 'Validation error'">Já existe uma solicitação com o email informado. Por favor, informe outro email.</span>
                <span v-else>{{message}}</span>
              </p>
            </div>
          </div>
          <div v-if="successful">
            <p><strong>Entraremos em contato com você</strong> para finalizar seu cadastro e ativar seu acesso na Ganbei.</p>
            <p >Se quiser ir adiantando, já pode <strong>agendar uma apressentação</strong>, par gente te mostrar como funciona o Ganbei e te passar dicas para você vender mais.</p>
            <a class="btn-schedule" href="https://calendly.com/ganbeiaassets/apresentacao-ganbei" target="_blank">Agendar apresentação.</a>
          </div>
        </div>
      </div>
      <div class="col-md-6" style="padding: 0px;margin-bottom: 50px;">
        <img
              src="@/assets/img/illust-register.png"
              style="max-width: 100%"
            />
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { vMaska } from "maska";
import axios from 'axios'

export default {
  name: "Register",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  directives: { maska: vMaska },
  data() {
    const schemaPf = yup.object().shape({
     
      leadname: yup
        .string()
        .required("Este campo é obrigatório."),
      email: yup
        .string()
        .required("Este campo é obrigatório.")
        .email("Verifique o email digitado"),
      phone: yup
        .string()
        .required("Este campo é obrigatório."),
      contactPreference: yup
        .string()
        .required("Este campo é obrigatório."),
      // contactTime: yup
      //   .string()
      //   .required("Este campo é obrigatório."),
      contactType: yup
        .string()
        .required("Este campo é obrigatório.")
    });

    const schemaPj = yup.object().shape({
      leadname: yup
        .string()
        .required("Este campo é obrigatório."),
      companyname: yup
        .string()
        .required("Este campo é obrigatório."),
      email: yup
        .string()
        .required("Este campo é obrigatório.")
        .email("Verifique o email digitado"),
      phone: yup
        .string()
        .required("Este campo é obrigatório."),
      contactPreference: yup
        .string()
        .required("Este campo é obrigatório."),
      // contactTime: yup
      //   .string()
      //   .required("Este campo é obrigatório."),
      contactType: yup
        .string()
        .required("Este campo é obrigatório.")
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schemaPf,
      schemaPj,
      contactPreference: "",
      contactTime: "",
      contactType: "2"
    };
  },
  computed: {
    // loggedIn() {
    //   return this.$store.state.auth.status.loggedIn;
    // },
  },
  mounted() {
    // if (this.loggedIn) {
    //   this.$router.push("/profile");
    // }
  },
  methods: {
    formValidation() {
      if(this.contactType == 1) {
        return this.schemaPf
      } else {
        return this.schemaPj
      }      
    },
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      console.log("TENTATIVA DE SOLICITAÇÃO DE CADASTRO", user)
      this.$store.dispatch("lead/register", user).then(
        (data) => {
          console.log(data);
          this.message = data.message;
          this.successful = true;
          this.loading = false;
          this.sendNewSolicitationEmail(user);
          this.sendNewSolicitationEmailForCustomer(user);
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
     async sendNewSolicitationEmail(dest) {
        try {
            await axios.post(
                //'http://localhost:3000/api/mail/solicitation',
                //'http://localhost:3003/api/mail/solicitation',
                `${process.env.VUE_APP_API_URL}mail/solicitation`,
                //'http://homolog.ganbei.io/api/mail/solicitation',
                {
                    username: dest.leadname,
                    c2a_button: "Acessar o painel de controle",
                    //c2a_link: "http://localhost:8080/login",
                    c2a_link: "http://homolog.ganbei.io/login",
                    email: dest.email,
                    phone: dest.phone,
                    contactPreference: dest.contactPreference,
                    contactTime: dest.contactTime,
                }
            );
            // this.productName = "";
            // this.productPrice = "";
            // this.$router.push("/");
        } catch (err) {
            console.log("erro send mail", err);
        }
    },
    async sendNewSolicitationEmailForCustomer(dest) {
        try {
            await axios.post(
                //'http://localhost:3000/api/mail/solicitation/customer',
                //'http://localhost:3003/api/mail/solicitation/customer',
                //'http://homolog.ganbei.io/api/mail/solicitation/customer',
                `${process.env.VUE_APP_API_URL}mail/solicitation/customer`,
                {
                    username: dest.leadname,
                    email: dest.email,
                    contactPreference: dest.contactPreference
                }
            );
            // this.productName = "";
            // this.productPrice = "";
            // this.$router.push("/");
        } catch (err) {
            console.log("erro send mail", err);
        }
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  display: block;
  margin-top: 10px;
}

.form-register {
  input[type=text] {
    text-transform: uppercase;
  }

  input[type=email] {
    text-transform: lowercase;
  }
}

.card-container.card {
  max-width: 450px !important;
  padding: 40px 40px;
}

.btn-schedule {
  text-align: center;
  padding: 10px;
  display: block;
  border-radius: 10px;
  background: rgb(171, 89, 235);
  color: #fff;
  margin-top: 50px;
}

.card {
  background-color: #fff;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.error-feedback {
  color: red;
}
</style>
