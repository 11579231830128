<template>
  <div id="app">
    <notifications />
    <div class="container-full d-flex">
      <div v-if="currentUser" :style="location.path === '/chat' ? 'display: none' : ''">
        <sidebar-menu />
      </div>
      <div class="container-full">
        <div v-if="currentUser" :style="location.path === '/chat' ? 'display: none' : ''" class="top-header">
          <div style="
              font-size: 14px;
              font-weight: 500;
              color: #8e4abc;
              margin-left: 20px">
                Bem vindo ao Ganbei. 
                <span class="alert-msgs" style="text-decoration:underline; padding-left:5px" v-if="sum.length > 0 || processingSolicitations.length > 0">
                  <router-link :to="{ name: 'solicitations' }" >
                  <span  v-if="sum.length > 0 && showAdminBoard">
                    Solicitações Pendentes: {{sum.length}}
                  </span>
                  <span v-if="processingSolicitations.length > 0">
                    solicitações em andamento.
                    <span>
                      {{processingSolicitations.length}}
                    </span> 
                  </span>
                  </router-link>
                </span>
                
                
          </div>
          <div>
            <div v-if="showAdminBoard" class="btn-group nav-dropdown dropdown-alert mr-3">
              <button type="button" class="dropdown-toggle no-arrow" data-toggle="dropdown" data-display="static" aria-expanded="false">
                <i class="bi bi-bell"></i>
              </button>
              <div v-if="sum.length > 0" class="alert-pushup">
                {{sum.length}}
              </div>
              <div class="dropdown-menu dropdown-menu-right">
                <div class="dropdown-item d-flex" v-for="item in pendingSolicitatios" :key="item.contact_status">
                  <router-link v-if="item.contact_status == 1" :to="{ name: 'solicitation-edit', params: { id: item.id } }" >
                    <span class="mr-2">{{item.name}} fez uma solicitação de cadastro.</span>
                    <span>( contactar por {{item.contact_preference}} {{item.contact_time}} )</span>
                  </router-link>
                </div>
              </div>
            </div>
            
            <div class="btn-group nav-dropdown">
              <button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-person-circle"></i> {{ currentUser.username }}
              </button>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link v-if="showAdminBoard" :to="{ name: 'profileadm' }" class="dropdown-item">
                  <i class="bi bi-person-badge"></i> Meus dados
                </router-link>
                <!--
                <router-link v-if="showAdminBoard" :to="{ name: 'list-admins' }" class="dropdown-item">
                  <i class="bi bi-person-badge"></i> Administradores
                </router-link>
                -->
                <router-link v-else :to="{ name: 'profile' }" class="dropdown-item">
                  <i class="bi bi-person-badge"></i> Meus dados
                </router-link>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click.prevent="logOut">
                  <i class="bi bi-box-arrow-left"></i> Sair
                </a>
              </div>
            </div>
          </div>
        </div>
        <router-view />
      </div>  
    </div>
  </div>
</template>
<script setup>
  //import BaseHeader from './components/organisms/BaseHeader.vue';
  //import ClientDetail from './components/organisms/ClientDetail.vue';
  //import ChatVisibility from "./components/atoms/ChatVisibility.vue";
  import SidebarMenu from "./components/SidebarMenu.vue";
  import LeadService from "./services/lead.service";
  import { onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { RouterLink, RouterView } from 'vue-router';

  const location = useRoute();
  //const router = useRouter();
  
  const isChat = ref(false);
  onMounted(async () => {
    console.log("chat", isChat.value)
  });

  watch(isChat, (newValue) => {
    console.log("chat", isChat.value)
  },
  {
    immediate: true,
  });
  watch(isChat, (oldValue) => {
    console.log("chat", isChat.value)
  },
  {
    immediate: true,
  });
</script>
<script>
import { provide } from 'vue';

export default {
  components: {
    SidebarMenu,
    //ChatVisibility
  },
  data() {
    return {
      //isChat: false,
      pendingSolicitatios: [],
      processingSolicitations: [],
      teste: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if ((this.currentUser && this.currentUser.roles == 1) || (this.currentUser && this.currentUser.roles == 4)) {
        return true;
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles == 2) {
        return true;
      }

      return false;
    },
    showUserBoard() {
      if (this.currentUser && this.currentUser.roles == 3) {
        return true;
      }

      return false;
    },
    sum() {
      return this.teste;
    },
    // retrieveLeads() {
    //   LeadService.getLeads()
    //     .then(response => {
    //       //this.pendingSolicitatios = response.data;
    //       response.data.forEach(item => {
    //         if (item.contact_status == 1) {
    //           this.pendingSolicitatios.push(item)
    //         }
    //         if (item.contact_status == 4) {
    //           this.processingSolicitations.push(item)
    //         }
    //       });
    //       //this.pendingSolicitatios = response.data;      
    //       console.log("METODO RETRIEVE LEADS ROOT",response.data);
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    // },
  },
  mounted() {
    //console.log('route.path', window.location.pathname);
    this.provideRefreshLeads();
    this.retrieveLeads();
    console.log("AAAAAAAAAAAAAUUUTTTHHHH", this.$store.state.auth);
    setTimeout(() => {
        if (this.pendingSolicitatios.length > 0) {
          this.teste = this.pendingSolicitatios
        } 
      }, 3000);
    
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
    retrieveLeads() {
      this.pendingSolicitatios.length = 0;
      LeadService.getLeads()
        .then(response => {
          //this.pendingSolicitatios = response.data;
          response.data.forEach(item => {
            if (item.contact_status == 1) {
              this.pendingSolicitatios.push(item)
            }
            if (item.contact_status == 4) {
              this.processingSolicitations.push(item)
            }
          });
          //this.pendingSolicitatios = response.data;      
          //console.log("METODO REFRESH LEADS ROOT",response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },
    refreshLeads() {
      this.teste.length = 0;
      LeadService.getLeads()
        .then(response => {
          //this.pendingSolicitatios = response.data;
          console.log("resposta antes do delay",response)
          //this.pendingSolicitatios = response.data;      
          //console.log("METODO REFRESH LEADS ROOT",response.data);
        })
      setTimeout(() => {
        LeadService.getLeads()
        .then(response => {
          //this.pendingSolicitatios = response.data;
          response.data.forEach(item => {
            if (item.contact_status == 1) {
              this.teste.push(item)
            }
          });
          //this.pendingSolicitatios = response.data;      
          //console.log("METODO REFRESH LEADS ROOT",response.data);
        })
        console.log("Delayed action executed", this.teste);
      }, 3000);
      
      
      //this.pendingSolicitatios.length = 0;
      //this.retrieveLeads();
      //console.log("REFRESCADO", this.pendingSolicitatios);
      console.log('Root Function Called');
    },
    provideRefreshLeads() {
      // Use the provide method to pass a reference to the root instance down to the child component
      provide('refreshLeads', this.refreshLeads);
    }
  }
};
</script>
<style lang="scss">
@import "./assets/css/style.scss";

.app {
  position: relative;
}
.top-header {
  width: 100%;
  height: 51px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background: #fff;
  position: relative
}
.top-header .alert-msgs a{
  color: rgb(142, 74, 188);
  font-size: 12px;
}
.top-header::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 51px;
  height: 54px;
  width: 25px;
  border-top-left-radius: 25px;
  box-shadow: 0 -25px 0 0 #ffffff;
  left: 0px;
}
.dropdown-toggle {
  &.no-arrow::after {
   display:none !important; 
  }
}
.dropdown-alert {
  .dropdown-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 26px;
    font-size: 13px;
    a {
      color: #1f1e1c;
    }
  }
}
.alert-pushup {
  width: 19px;
    height: 19px;
    border-radius: 100%;
    background: red;
    color: #fff;
    font-size: 12px;
    padding: 5px;
    line-height: 10px;
}
</style>
