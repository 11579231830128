import axios from 'axios';

//const API_URL = 'http://localhost:3000/api/auth/';
//const API_URL = 'http://localhost:3003/api/auth/';
const API_URL = process.env.VUE_APP_API_URL + 'auth/';


class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'signin', {
        email: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  businessCredentials(user) {
    return axios
      .post(API_URL + 'businesscredentials', {
        email: user.username
      })
      .then(response => {
        
        localStorage.setItem('businessCredentials', JSON.stringify(response.data));
        

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    //console.log("service log",user);
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.sellerEmail,
      brandName: user.contactType == 1 ? user.username : user.brandName,
      businessName: user.businessName,
      businessType: user.contactType,
      businessDoc: user.businessDoc,
      businessPhone: user.sellerPhone,
      businessEmail: user.sellerEmail,
      businessAddress: user.businessAddress,
      businessCity: user.businessCity,
      businessState: user.businessState,
      businessZipcode: user.businessZipcode,
      sellerPhone: user.sellerPhone,
      sellerEmail: user.sellerEmail,
      sellerDoc: user.sellerDoc,
      sellerBirthday: user.sellerBirthday,
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("auth service log", error.response.data.message);
    });
  }

  registerAdm(user) {
    //console.log("service log",user);
    return axios.post(API_URL + 'register-admn', {
      username: user.username,
      email: user.sellerEmail
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("auth service log", error.response.data.message);
    });
  }
}

export default new AuthService();
