import axios from 'axios';
import authHeader from './auth-header';

//const API_URL = 'http://localhost:3000/api/lead/';
//const API_URL = 'http://localhost:3003/api/lead/';
//const API_URL = 'http://homolog.ganbei.io/api/lead/';
const API_URL = `${process.env.VUE_APP_API_URL}lead/`;

class LeadService {
  getLeads() {
    //return axios.get(API_URL + 'all');
    return axios.get(API_URL + 'pending');
  }

  get(id) {
    return axios.get(API_URL + 'findOne/' + `${id}`);
  }

  // register(user) {
  //   console.log(user);
  //   return axios.post(API_URL + 'signup', {
  //     username: user.username,
  //     email: user.sellerEmail,
  //     businessName: user.businessName,
  //     businessType: "PF",
  //     businessDoc: user.businessDoc,
  //     businessPhone: user.sellerPhone,
  //     businessEmail: user.sellerEmail,
  //     sellerPhone: user.sellerPhone,
  //     sellerEmail: user.sellerEmail,
  //     sellerDoc: user.sellerDoc,
  //   })
  //   .then(response => {
  //     return response.data;
  //   })
  //   .catch(error => {
  //     if(error.message === "Validation error") {
  //       error.message === "Deu ruim"
  //     }
  //     res.status(500).send({
        
  //         message:
  //         err.message
        
        
  //     });
  //   });
  // }

  update(id) {
    return axios.put(API_URL + 'update/' + `${id}`, {
        contact_status: user.contactStatus,
        contact_status: user.contactStatus,
      });
  }

  register(user) {
    return axios.post(API_URL + 'create', {
      brandname: user.companyname,
      leadname: user.leadname,
      email: user.email,
      phone: user.phone,
      contactPreference: user.contactPreference,
      contactTime: user.contactTime,
      contactType: user.contactType,
    });
  }
}

export default new LeadService();
